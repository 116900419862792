.map-container {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  /* width:100%; */
  /* transition: all 1s; */
}

.map-container-width {
  max-width: 70%;
}
/* .map-container{

} */

.places-types {
  color: #ffffff;
  z-index: 1;
  position: absolute;
  top: 45px;
  left: 0;
  margin: 12px;
}
.geocoder {
  /* z-index: 1; */
  display: inline-block;
}
.mapboxgl-ctrl-geocoder {
  min-width: 100%;
}
.mapboxgl-ctrl-geocoder--input {
  height: 36px;
  padding: 10px !important;
}
.mapboxgl-ctrl-geocoder--icon {
  display: none !important;
}

.custom-input { 
  border:0;
  outline: 0;
  border-radius: 50px;
  margin-left: 5px;
  margin-right: 5px;
  padding: 5px 10px;
  /* width: 250px; */
}
.custom-select { 
  border:0;
  outline: 0;
  margin-left: 5px;
  margin-right: 5px;
  /* padding: 0px 5px; */
  width: 200px;
  min-width: 120px;
	-moz-box-sizing: border-box; 
	-webkit-box-sizing: border-box; 
	box-sizing: border-box;
  height: 35px;
}

.filters-container-top {
  color: #ffffff;
  background-color: navy;
  /* padding: 10px; */
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  font-size: 16px;
  line-height: 25px;
  height: 50px;
}
.filters-container-bottom {
  font-size: 16px;
  line-height: 1.5rem;
  color: #ffffff;
  background-color: navy;
  /* padding: 10px; */
  z-index: 2;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}

.compass-container {
  position: fixed;
  right: 20px;
  top: 60px;
  z-index: 1;
  width: 42px;
  height: 42px;
  /* display: none; */
}

.availible-sold-container {
  position: fixed;
  right: 90px;
  top: 60px;
  z-index: 1;
  padding: 10px 30px;
  border-radius: 20px;
  color: white;
  background-color: rgba(0, 0, 0, 0.6);
  display: none;
  transition: 0.5s;
}
.phone-number-wrapper{
  color: white;
  /* padding: 10px; */
  z-index: 2;
  position: absolute;
  bottom: 70px;
  left: 15px;
  background-color: rgba(90, 90, 90, 0.7);
  padding:10px;
  border-radius: 10px;

  box-shadow: 2px 2px 20px 3px rgba(157, 157, 157,0.75);
  -webkit-box-shadow: 2px 2px 20px 3px rgba(157, 157, 157,0.75);
  -moz-box-shadow: 2px 2px 20px 3px rgba(157, 157, 157,0.75);
}

/* scroll bar code */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
 }
 ::-webkit-scrollbar-thumb {
  background: #b8b7bd;
  border-radius: 5px;
 }
 ::-webkit-scrollbar-thumb:hover{
  background: navy;
 }
 ::-webkit-scrollbar-track{
  /* background: ; */
  border-radius: 2px;
  margin-left: 10px;
  box-shadow: inset 0px 0px 0px #f0f0f0;
 }
/* end */

.clypso-bay-btn{
  right: 15px;
  position: absolute;
  bottom: 70px;
  padding:10px;
  border-radius: 10px;
  border: 2px solid navy;
  background-color: white;
  color: navy;
  cursor: pointer;
}
.clypso-bay-btn:hover {
  background-color: navy;
  color: white;
}
.clypso-bay-btn span {
  font-weight: bolder;
}
.zoom-control-wrapper{
  background-color:navy;
  /* padding: 10px; */
  z-index: 2;
  position: absolute;
  bottom: 130px
}
.zoom-control-wrapper-right {
  right: 15px;
}
.close-button {
  cursor: pointer;
  background-color: navy;
  color:white;
  padding: 5px 15px;
  margin-left: -40px;
}
.tour-btn {
  cursor: pointer;
  background-color: navy;
  border: none;
  color: white;
  padding: 5px 10px;
  border-radius: 3px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 0px 3px;
}
.btn-blue {
  cursor: pointer;
  background-color: navy;
  border: none;
  color: white;
  padding: 5px 10px;
  border-radius: 3px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
}
.btn-sm {
  cursor: pointer;
  background-color: navy;
  border: none;
  color: white;
  padding: 2px 8px;
  border-radius: 3px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 9px;
  margin: 2px;
}
.primary-color{
  color: navy;
}
.pop-custom-style {
  /* font-family: 'Gotham Medium';
  color: #F7931E;
  background-color: #196A5F; */
  color: #ffffff;
  background-color: navy;
  border-radius: 3px;
}
.pop-custom-style div {
  /* font-size: 16px; */
  line-height: 10px;
}

.tableFixHead    { overflow-y: auto; max-height: 280px; font-size: 12px; text-align: center; margin-top: 7px; }
.tableFixHead th { position: sticky; top: 0; color: #ffffff; background-color: navy; border:navy; }

.zoom-control-wrapper-left{
  left: 15px;
}
.zoom-control-wrapper svg {
  cursor: pointer;
  font-size: 25px;
  margin: 5px 5px 0px 5px;
  color: #ffffff;
}
.zoom-control-wrapper svg:last-child {
  margin-bottom: 5px;
  margin-top: 0px;
}


/* pop up html styling */
@keyframes slideFromRightAnimation {
  from {right: -400px;}
  to {right: 0px;}
}
@keyframes slideToRightAnimation {
  from {right: 0px;}
  to {right: -400px;}
}
body {
  overflow-x: hidden;
}
.pop-up-marker-details {
    background-color: rgb(255, 255, 255);
    min-width: 350px;
    color: #000000;
    padding: 25px;
    /* font: 15px/24px monospace; */
    z-index: 3;
    position: absolute;
    top: 15%;
    right: 0;
    margin: 12px;
    border-radius: 5px;
    animation-name: slideFromRightAnimation;
    animation-duration: 1s;
}

.pop-up-img {
  padding: 50px;
  margin: 25px 0px;
  text-align: center;
  max-width: 250px;
  height: auto;
}

.distance-time-details{
  background-color: #e6e6e6;
  font-weight: bold;
  border: 1px solid black;
  padding: 10px 0px;
  text-align: center;
}
.distance-details {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.time-details {
  border-left: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
/* end */

.range-slider{
  margin-top: 25px;
}
.lot-search-panel-show {
  background-color: rgb(255, 255, 255);
  width: 30%;
  color: #000000;
  /* font: 15px/24px monospace; */
  z-index: 3;
  position: absolute;
  top: 50px;
  right: 0;
  /* animation-name: slideFromRightAnimation;
  animation-duration: .3s; */
}
.range-slider-wrapper{
    padding: 0px 25px 0px 25px;
}
.lot-search-panel-hide{
  background-color: rgb(255, 255, 255);
  /* width: 30%; */
  color: #000000;
  padding: 25px;
  /* font: 15px/24px monospace; */
  z-index: 3;
  position: absolute;
  top: 50px;
  right: 0;
  /* animation-name: slideToRightAnimation;
  animation-duration: .3s; */
}
.modal-90w {
  min-width: 90%;
}
.border-rb {
  border-right: 2px solid rgba(223, 221, 221, 0.829);
  border-bottom: 2px solid rgba(223, 221, 221, 0.829);
  padding: 10px;
  flex: 1;
}
.border-b{
  border-bottom: 2px solid rgba(223, 221, 221, 0.829);
  padding: 10px;
  flex: 1;
}
.circle {
  border-radius: 50%;
  padding: 3px;
}
.circle:hover{
  cursor: pointer;
}

.img-size{
  max-width: 75px;
  max-height: 75px;
  padding: 5px;
  transition: 0.2s;
}

.img-size:hover{
  padding: 0;
  max-width: 75px;
  max-height: 75px;
}

.custom-marker {
  border: none;
  cursor: pointer;
  height: 56px;
  width: 56px;
  background-size: contain;
  background-color: rgba(0, 0, 0, 0);
}

.custom-text-marker {
  color:#ffffff;
  background-color: rgb(100, 100, 100);
  padding: 1px 4px;
  border-radius: 2px;
  font-weight: bold;
  font-size: 12px;
}

.amenities-marker {
  cursor: pointer;
  margin-left: -21px;
  margin-top: -50px;
  width: 42px;
  height: 50px;
  transform: translate3d(1214px, 596px, 0px);
  /* z-index: 1; */
}
.air-port-marker {
  border: none;
  cursor: pointer;
  height: 72px;
  width: 72px;
  background-size: contain;
  background-color: rgba(0, 0, 0, 0);
}
.finish-marker {
  border: none;
  cursor: pointer; 
  margin-top: -25px;
  width: 39px;
  height: 56px;
  transform: translate3d(822px, 438px, 0px);
}


/* place type pop up css starts */
.modal.fade.modal-right .modal-dialog {
  transform: translate(125%, 0px);
}

.modal.show.modal-right .modal-dialog {
  transform: none;
}
/* place type pop up css ends */

.red-green-marker {
  cursor: pointer;
  width: 17px;
  height: 17px;
  transform: translate3d(516px, 195px, 0px);
  /* z-index: 1; */
  font-family: proxima-nova, serif;
}

/* custom code for popup */

/* Marker tweaks */
.mapboxgl-popup-close-button {
  display: none;
}

.mapboxgl-popup-content {
  font: 400 15px/22px 'Source Sans Pro', 'Helvetica Neue', Sans-serif;
  padding: 0;
  /* width: 180px; */
}

.mapboxgl-popup-content-wrapper {
  padding: 1%;
}

.mapboxgl-popup-content span {
  /* font-family: 'Gotham Medium';
  color: #F7931E;
  background-color: #196A5F; */
  color: #ffffff;
  background-color:navy;
  margin: 0;
  display: block;
  padding: 10px;
  border-radius: 3px 3px 0 0;
  margin-top: -15px;
}

/* .mapboxgl-popup-content h4 {
  margin: 0;
  display: block;
  padding: 10px;
  font-weight: 400;
} */

.mapboxgl-popup-content div {
  padding: 10px;
}

.mapboxgl-container .leaflet-marker-icon {
  cursor: pointer;
}

.mapboxgl-popup-anchor-top > .mapboxgl-popup-content {
  margin-top: 15px;
}
.mapboxgl-popup {
  /* z-index: 2; */
  cursor: pointer;
}
.mapboxgl-popup-tip {

  /* border-top-color: #196A5F !important; */
  border-top-color: navy !important;
}

/* end custom css for popup */



/* loader code as a backup not in use */

.custom-loading {
  -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
  animation: sk-scaleout 1.0s infinite ease-in-out;
  background-color: rgb(4, 49, 85);
  border-radius: 100%;
  height: 6em;
  width: 6em;
}


.custom-loader-container {
  flex-direction: column;
  align-items: center;
  background-color: #e6e4e0;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;
}
.waiting-text {
  font-weight: bold;
  margin: 10px;
  color: rgb(4, 49, 85);
}

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1.0);
    opacity: 0;
    transform: scale(1.0);
  }
}
/* loader code */